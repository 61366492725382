<template>
    <div>
        <!-- Breadcrumbs -->
        <v-container v-if="smAndUp">
            <v-row>
                <v-col>
                    <v-breadcrumbs
                        :items="breadCrumbs"
                        divider=">"
                    ></v-breadcrumbs>
                </v-col>
            </v-row>
        </v-container>

        <!-- 会社概要 -->
        <v-container>
            <v-row justify="center" class="mb-15">
                <v-col cols="auto" align="center">
                    <h1><heading>会社概要</heading></h1>
                </v-col>
            </v-row>
            <v-row justify="center" class="mb-10">
                <v-col
                    cols="auto"
                    align="center"
                    :class="smAndUp ? 'text-h5 font-weight-light' : 'text-h6'"
                    >企業理念</v-col
                >
            </v-row>

            <!-- ビジョン -->
            <v-row justify="center">
                <v-col
                    cols="auto"
                    align="center"
                    :class="smAndUp ? 'text-h5 font-weight-light' : 'text-h6'"
                    >ビジョン</v-col
                >
            </v-row>
            <v-row justify="center">
                <v-col cols="auto" align="center">
                    <div
                        class="message font-weight-light"
                        :class="smAndUp ? 'text-h4 my-4 ' : 'text-h6 my-4'"
                    >
                        ひとりでも多くの人たちが<br />
                        心と身体の健康を実現し、 <br />
                        前向きに人生を歩めている<br />
                        社会を創りたい。<br />
                    </div>
                </v-col>
            </v-row>
            <!-- ミッション -->
            <v-row justify="center">
                <v-col
                    cols="auto"
                    align="center"
                    :class="smAndUp ? 'text-h5 font-weight-thin' : 'text-h6'"
                    >ミッション</v-col
                >
            </v-row>
            <v-row justify="center">
                <v-col cols="auto" align="center">
                    <div
                        class="message font-weight-light"
                        :class="smAndUp ? 'text-h4 my-4 ' : 'text-h6 my-4'"
                    >
                        お客様ひとりひとりの<br />
                        価値観に沿った健康的で<br />
                        楽しい生活習慣を<br />
                        共に創り続けます。<br />
                        その実現に向けて、<br />
                        トレーナーは日々学習,協働を、 <br />
                        経営は身近で快適で<br />
                        通いやすい空間を、<br />
                        提供し続けます。
                    </div>
                </v-col>
            </v-row>

            <!-- 代表あいさつ -->
            <v-row justify="center">
                <v-col
                    cols="auto"
                    align="center"
                    :class="smAndUp ? 'text-h5 font-weight-light' : 'text-h6'"
                    >代表挨拶</v-col
                >
            </v-row>
            <v-row justify="center">
                <v-col cols="4">
                    <v-img :src="imageCeo" :key="imageCeo"></v-img>
                </v-col>
                <v-col
                    cols="8"
                    md="4"
                    :class="smAndUp ? 'text-h6' : 'text-body-1'"
                    class="font-weight-light"
                    align-self="center"
                    >株式会社REVOIST<br />代表取締役 井上 大輔<br /><br />
                    東京大学大学院身体運動科学科卒<br />パーソナルトレーナー</v-col
                >
            </v-row>
            <v-row justify="center">
                <v-col cols="auto" align="center">
                    <div
                        class="message font-weight-light"
                        :class="smAndUp ? 'text-h4 my-4 ' : 'text-h6 my-4'"
                    >
                        ひとりでも多くの方にとって<br />
                        パーソナルトレーニングを<br />
                        受けやすく、続けやすいサービスへ
                    </div>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col md="10">
                    <v-card flat>
                        <heading>
                            <span class="red--text">代</span>表の経歴
                        </heading>
                        <v-col
                            align="left"
                            :class="smAndUp ? 'text-h6' : 'text-body-1'"
                            class="px-0 font-weight-light"
                        >
                            私は、早稲田大学、東京大学大学院で身体運動科学を研究しながら、その知識を持って、パーソナルトレーナーとして活動してきました。<br />
                            ダイエットやボディメイクを中心に、ひとりひとりの価値観に寄り添いながら、その方のカラダの悩みを一緒に解決し、心と身体の健康を実現してきました。<br />
                        </v-col>
                        <heading
                            ><span class="red--text">健</span
                            >康産業・フィットネス業界の課題</heading
                        >
                        <v-col
                            align="left"
                            :class="smAndUp ? 'text-h6' : 'text-body-1'"
                            class="px-0 font-weight-light"
                        >
                            それと同時に『ジムで自己流の間違ったトレーニングを頑張る方たち』『ジムに入会するも、半年後には全く通わなくなり、退会する方たち』『高額のパーソナルトレーニングジムに入会し、その後、リバウンドしている方たち』
                            といった今の日本の健康産業、フィットネスクラブ業界の課題も目の当たりにしてきました。
                        </v-col>
                        <heading
                            ><span class="red--text">サ</span
                            >ービスの課題</heading
                        >
                        <v-col
                            align="left"
                            :class="smAndUp ? 'text-h6' : 'text-body-1'"
                            class="px-0 font-weight-light"
                        >
                            これらの経歴と経験から、わかったことが大きく２つありました。<br />
                            1つ目は、パーソナルトレーニングは、運動初心者のダイエットやボディメイクにおいて最も効果的かつ最高の手段であること。<br />
                            2つ目は、パーソナルトレーニングは、高額すぎる料金体系や無理のある糖質制限ダイエットなどから、多くの方にとって受けることや続けることの敷居が高すぎること。
                        </v-col>
                        <heading
                            ><span class="red--text">R</span
                            >EVOISTの挑戦</heading
                        >
                        <v-col
                            align="left"
                            :class="smAndUp ? 'text-h6' : 'text-body-1'"
                            class="px-0 font-weight-light"
                        >
                            そこで、私たちREVOISTは、ひとりでも多くの方にとって、パーソナルトレーニングを受けやすく、続けやすいサービスにすることに挑戦します。<br />
                            REVOISTは、パーソナルトレーニング業界に革命を起こし、日本の健康産業として、新たな健康インフラの形をつくることを目指します。
                        </v-col>
                        <heading
                            ><span class="red--text">R</span
                            >EVOISTの約束</heading
                        >
                        <v-col
                            align="left"
                            :class="smAndUp ? 'text-h6' : 'text-body-1'"
                            class="px-0 font-weight-light"
                        >
                            今まさに健康的なカラダづくりを始めようと、勇気を出して一歩を踏み出そうとこのホームページを読んでいるあなたへ。
                            私たちREVOISTは、あなたのカラダの悩みをなくし、あなたのカラダを健康的に、美しくすることを約束します。
                        </v-col>
                    </v-card>
                </v-col>
            </v-row>

            <!-- レボイストのロゴ -->
            <v-row justify="center">
                <v-col cols="auto" align="center">
                    <heading>レボイストのロゴ</heading>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="6" md="3">
                    <v-img :src="imageLogo" class="ma-auto"></v-img>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col md="10">
                    <v-card flat>
                        <v-col
                            align="left"
                            :class="smAndUp ? 'text-h6' : 'text-body-1'"
                            class="px-0 font-weight-light"
                        >
                            あなた(赤)と私たち(黒)が1対1で真摯に向き合い、寄り添い続け、共に右肩上がりに成長していけるように。あなたのカラダの革命（REVO）を私たち専門家（IST）が支えます。
                        </v-col>
                    </v-card>
                </v-col>
            </v-row>

            <!-- 会社概要 -->
            <v-row justify="center">
                <v-col md="10">
                    <v-card flat>
                        <heading>会社概要</heading>
                        <v-row justify="center" class="ma-2">
                            <v-col
                                cols="12"
                                :class="smAndUp ? 'text-body-1' : 'text-body-2'"
                                class="font-weight-light"
                            >
                                <v-row class="business-summary">
                                    <v-col class="key" cols="4" md="3"
                                        >社名</v-col
                                    >
                                    <v-col class="value" cols="8" md="9"
                                        >株式会社REVOIST</v-col
                                    >
                                </v-row>
                                <v-row class="business-summary">
                                    <v-col class="key" cols="4" md="3"
                                        >所在地</v-col
                                    >
                                    <v-col class="value" cols="8" md="9"
                                        >千葉県市川市伊勢宿17－12
                                        グランディールK101</v-col
                                    >
                                </v-row>
                                <v-row class="business-summary">
                                    <v-col class="key" cols="4" md="3"
                                        >設立日</v-col
                                    >
                                    <v-col class="value" cols="8" md="9"
                                        >2015年12月8日</v-col
                                    >
                                </v-row>
                                <v-row class="business-summary">
                                    <v-col class="key" cols="4" md="3"
                                        >代表取締役</v-col
                                    >
                                    <v-col class="value" cols="8" md="9"
                                        >井上大輔</v-col
                                    >
                                </v-row>
                                <v-row class="business-summary">
                                    <v-col class="key" cols="4" md="3"
                                        >主要取引銀行</v-col
                                    >
                                    <v-col class="value" cols="8" md="9"
                                        >三菱UFJ銀行 行徳支店</v-col
                                    >
                                </v-row>
                                <v-row class="business-summary">
                                    <v-col class="key" cols="4" md="3"
                                        >事業内容</v-col
                                    >
                                    <v-col class="value" cols="8" md="9"
                                        >美容と健康に関する指導</v-col
                                    >
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>

            <!-- 沿革 -->
            <v-row justify="center">
                <v-col md="10">
                    <v-card flat>
                        <heading>沿革</heading>
                        <v-row justify="center" class="ma-1">
                            <v-col
                                cols="12"
                                :class="smAndUp ? 'text-body-1' : 'text-body-2'"
                                class="font-weight-light"
                            >
                                <v-row>
                                    <v-col class="history-key" cols="3"
                                        >2015.12</v-col
                                    >
                                    <v-col cols="9">株式会社REVOIST設立</v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="history-key" cols="3"
                                        >2016.05</v-col
                                    >
                                    <v-col cols="9"
                                        >REVOIST1号店 行徳駅前店 OPEN</v-col
                                    >
                                </v-row>
                                <v-row>
                                    <v-col class="history-key" cols="3"
                                        >2017.10</v-col
                                    >
                                    <v-col cols="9">行徳駅前店 移転</v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="history-key" cols="3"
                                        >2018.09</v-col
                                    >
                                    <v-col cols="9"
                                        >REVOIST2号店 妙典末広店 OPEN</v-col
                                    >
                                </v-row>
                                <v-row>
                                    <v-col class="history-key" cols="3"
                                        >2019.06</v-col
                                    >
                                    <v-col cols="9"
                                        >REVOIST3号店 南行徳駅前店 OPEN</v-col
                                    >
                                </v-row>
                                <v-row>
                                    <v-col class="history-key" cols="3"
                                        >2019.12</v-col
                                    >
                                    <v-col cols="9"
                                        >REVOIST4号店 妙典イオン前店 OPEN</v-col
                                    >
                                </v-row>
                                <v-row>
                                    <v-col class="history-key" cols="3"
                                        >2020.04</v-col
                                    >
                                    <v-col cols="9"
                                        >REVOIST5号店 妙典塩焼店 OPEN</v-col
                                    >
                                </v-row>
                                <v-row>
                                    <v-col class="history-key" cols="3"
                                        >2021.03</v-col
                                    >
                                    <v-col cols="9"
                                        >REVOIST6号店 葛西環七通り店 OPEN</v-col
                                    >
                                </v-row>
                                <v-row>
                                    <v-col class="history-key" cols="3"
                                        >2021.09</v-col
                                    >
                                    <v-col cols="9"
                                        >REVOIST7号店 行徳駅前南店 OPEN</v-col
                                    >
                                </v-row>
                                <v-row>
                                    <v-col class="history-key" cols="3"
                                        >2021.11</v-col
                                    >
                                    <v-col cols="9"
                                        >REVOIST8号店 南行徳SOCOLA店 OPEN</v-col
                                    >
                                </v-row>
                                <v-row>
                                    <v-col class="history-key" cols="3"
                                        >2022.06</v-col
                                    >
                                    <v-col cols="9"
                                        >REVOIST9号店 平井蔵前橋通り店
                                        OPEN</v-col
                                    >
                                </v-row>
                                <v-row>
                                    <v-col class="history-key" cols="3"
                                        >2022.07</v-col
                                    >
                                    <v-col cols="9"
                                        >REVOIST10号店 浦安駅前店 OPEN</v-col
                                    >
                                </v-row>
                                <v-row>
                                    <v-col class="history-key" cols="3"
                                        >2023.01</v-col
                                    >
                                    <v-col cols="9"
                                        >REVOIST11号店 妙典三丁目店 OPEN</v-col
                                    >
                                </v-row>
                                <v-row>
                                    <v-col class="history-key" cols="3"
                                        >2023.06</v-col
                                    >
                                    <v-col cols="9"
                                        >REVOIST12号店 葛西駅前店 OPEN</v-col
                                    >
                                </v-row>
                                <v-row>
                                    <v-col class="history-key" cols="3"
                                        >2024.05</v-col
                                    >
                                    <v-col cols="9"
                                        >REVOIST13号店 行徳駅前一丁目店
                                        OPEN</v-col
                                    >
                                </v-row>
                                <v-row>
                                    <v-col class="history-key" cols="3"
                                        >2024.06</v-col
                                    >
                                    <v-col cols="9"
                                        >REVOIST14号店 南行徳新井店 OPEN</v-col
                                    >
                                </v-row>
                                <v-row>
                                    <v-col class="history-key" cols="3"
                                        >2024.10</v-col
                                    >
                                    <v-col cols="9"
                                        >REVOIST15号店 平井ゆりのき橋通り店
                                        OPEN</v-col
                                    >
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>

            <v-row justify="center">
                <v-col md="10">
                    <v-card flat>
                        <heading>採用情報</heading>
                        <v-col
                            align="left"
                            :class="smAndUp ? 'text-h6' : 'text-body-1'"
                            class="px-0 font-weight-light"
                        >
                            お客様起点で日々、学習と協働ができ、真摯さと誠実さを兼ね備え、REVOISTのパーソナルトレーニングを健康インフラにすることに一緒に挑戦できるプロのパーソナルトレーナーを募集しています。
                        </v-col>
                        <v-col
                            cols="12"
                            md="8"
                            :class="smAndUp ? 'text-body-1' : 'text-body-2'"
                            class="font-weight-light"
                        >
                            <v-row class="table-border">
                                <v-col class="blue lighten-4" cols="4"
                                    >給与</v-col
                                >
                                <v-col cols="8">契約内容に準じる</v-col>
                            </v-row>
                            <v-row class="table-border">
                                <v-col class="blue lighten-4" cols="4"
                                    >勤務地</v-col
                                >
                                <v-col cols="8">契約内容に準じる</v-col>
                            </v-row>
                            <v-row class="table-border">
                                <v-col class="blue lighten-4" cols="4"
                                    >応募資格</v-col
                                >
                                <v-col cols="8">契約内容に準じる</v-col>
                            </v-row>
                        </v-col>
                        <v-col
                            align="left"
                            :class="smAndUp ? 'text-h6' : 'text-body-1'"
                            class="px-0 font-weight-light"
                        >
                            創業7年ですが、地域のお客様から支持され、東西線沿線（千葉県市川市エリア）で最大規模となる11店舗のパーソナルジムを運営しています。<br />
                            お客様の期待に応えるかたちで、向こう3年間で25店舗、10年後には80店舗、まで拡大する事業計画を描いており、そのための仲間を募集しています。<br /><br />
                            弊社の最大の特徴は「真のお客様起点」です。<br />
                            「ひとりでも多くの人たちが心と身体の健康を実現し、前向きに人生を歩めている社会を創りたい」というビジョンを掲げ、短期の肉体改造というよりは、長期でカウンセリング要素の強い、お客様の人生に深く寄り添っていく伴走モデルを大切にしています。<br />
                            （弊社ではお客様の専属トレーナーとなって頂くため、お客様のご意向次第では、数年間継続して担当頂くこともよくあります）<br /><br />
                            また、日々のトレーニングや店舗環境の改善につきましても、お客様の声を収集し、その声に応えるかたちで進めているため、自己満足にならず、トレーナーとして絶えず成長することができています。<br /><br />
                            将来キャリアという点でも、トレーナーには、マネジメントに進む道、トレーナーの専門性を活かした役割に進む道、など様々な機会をREVOIST内に創り続けており、弊社に在籍しながら多種多様な経験を積
                            んでいくことが可能です。<br />
                            組織全体がフラットで風通しが良く、マネジメントラインとトレーナー、トレーナー同士もとても仲が良く、日頃から腹を割った話し合いができています。<br />
                            互いに感謝と尊敬の念をもって仕事に携わっており、学び合い、高め合いながら、REVOIST全員でお客様の期待に向き合っています。<br /><br />
                            なぜそんなことができると思いますか？<br />
                            それは代表の井上（早稲田大学、東京大学大学院にて身体運動科学を専攻。気さくで純粋で真っ直ぐな社長で、トレーナー全員から愛されています。）
                            にお会い頂くとすぐに感じ取れると思いますが、井上自身が、トレーナーの働きがいや働きやすさを高めていくことが、お客様へのサービスの質を高め、お客様ひとりひとりの価値観に沿ったトレーニングの実現に繋がり、REVOISTに関わるすべてのかたを元気にしていけると心の底から信じているからです。<br /><br />
                            だからこそ、仲間になってくださるトレーナーのかたには、そういった機会を活かし、自身の人間力を自らアップデートし続けていくことが期待されます。<br />
                            そのため「お客様起点で柔軟に謙虚に動ける能動的なかた・パーソナルトレーナーという仕事に誇りを持ち更なる成長を目指しているかた・チーム協働でお客様の期待に応えていくことを楽しめるかた」からの応募をお待ちしています。<br /><br />
                            パーソナルトレーニング業界は世の中的な認知は高まってきているものの、まだまだ利用したことが無いかたも多く、サービスの在り方にも改善余地を残している業界です。<br />
                            そんな伸びしろあるマーケットで、新しいパーソナルトレーニングの在り方を発信し続けているREVOISTは、いつの日か必ず掲げたビジョンを成し遂げます。<br />
                            そんなジムを私たちと一緒に創っていきませんか？お会いできるのを楽しみにしています。
                        </v-col>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <footer-contents></footer-contents>
    </div>
</template>

<script>
import Heading from '@/components/Heading.vue'
import FooterContents from '@/components/FooterContents.vue'

export default {
    mounted() {
        document.title = `${this.$route.meta.title} | パーソナルトレーニングジム【REVOISTレボイスト】`
    },
    components: {
        Heading,
        FooterContents,
    },
    data() {
        return {}
    },
    computed: {
        breadCrumbs() {
            return [
                {
                    text: 'トップ',
                    disabled: false,
                    href: './',
                },
                {
                    text: '会社概要',
                    disabled: true,
                    href: 'company',
                },
            ]
        },
        smAndUp() {
            return this.$vuetify.breakpoint.smAndUp
        },
        imageCeo() {
            const path = this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_company/白衣先生.png'
                : 'static/sp_company/company_img01.png'
            return path
        },
        imageLogo() {
            return 'static/big_logo.svg'
        },
    },
}
</script>

<style lang="scss" scoped>
.message {
    line-height: 1.5em;
}
.table-border {
    border: 1px solid white;
}
.business-summary {
    > .key {
        border-bottom: 1px solid #c30013;
    }
    .value {
        border-bottom: 1px solid gray;
    }
}
.history-key {
    display: flex;
    align-items: center;
    text-align: center;

    &::after {
        content: '';
        height: 1px;
        background-color: #c30013;
        flex-grow: 1;
        margin-left: 20px;
        padding-right: 10px;
    }
}
</style>
